import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import styled from "styled-components";
import FAQ from "../components/FrequentlyAskedQ";
import Breadscrum from "../components/Breadscrum";
import Jumbotron from "../components/pohrby/Jumbotron";
import LinkButton from "../components/pohrby/LinkButton";
import CallButton from "../components/pohrby/CallButton";
import Img from "gatsby-image";
import { useMediaQuery } from 'react-responsive';

import Poplatky from "../components/pohrby/Poplatky";
import RecenzePohreb from "../components/pohrby/RecenzePohreb";
import SupportPanel from "../components/SupportPanel";
import ProcesBezObradu from "../components/pohrby/ProcesBezObradu";
import Features from "../components/pohrby/Features";
import SeVsimPomuzeme from "../components/pohrby/SeVsimPomuzeme";
import Press from "../components/pohrby/Press";
import KdeFungujeme from "../components/pohrby/KdeFungujeme";
import Naceneni from "../components/pohrby/Naceneni";
import StructuredHodnoceni from "../components/pohrby/StucturedHodnoceni";
import Block from "../components/Block";
import RegionsV2 from "../components/brandingV2/RegionsV2";
import NaceneniV2 from "../components/brandingV2/NaceneniV2";


const faqData = [
  {
    otazka: "Co přesně znamená kremace bez obřadu?",
    odpoved: "Kremace bez obřadu je postup, při kterém je zesnulý zpopelněn bez předchozího smutečního obřadu nebo přítomnosti blízkých. Vám je následně doručen popel v základní urně. Forma rozloučení pak už zůstává jen na vás. Nemusíte se tedy rozloučit okamžitě a časově se tak omezovat. Můžete smuteční obřad udělat až to bude nejvhodnější pro všechny pozůstalé. Poslední rozloučení pak můžete proběhnout v přírodě, doma nebo na oblíbeném místě zesnulého."
  },
  {
    otazka: "Kdy vás můžu kontaktovat?",
    odpoved: "Kdykoliv. Naše zákaznická linka je vám k dispozici 24 hodin 7 dní v týdnu ve dne i v noci na čísle 315 558 136. Pokud se rozhodne pro kremaci bez obřadu, kontaktujete nás prosím co nejdříve. Je možné, že tak předejdete zbytečným poplatkům od nemocnic a jiných institucí."
  },
  {
    otazka: "Kdy a jak se platí?",
    odpoved: "Platba zálohy probíhá vždy předem. Je možné ji provést buď bankovním převodem nebo online přes platební bránu. Fakturu s údaji o platbě obdržíte po vytvoření závazné objednávky. Po pohřbu zákazník obdrží druhou fakturu za zbytek částky která není obsažená v záloze. Například v případě, že využijete i některých dalších služeb které nabízíme: jiná urna, květiny a podobně. Moc rádi vám v případě zájmu zařídíme i platbu na splátky skrz naše partnery. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Budu platit něco navíc?",
    odpoved: `Poplatky navíc budete platit pouze v případě, kdy by k vyzvednutí zesnulého došlo po více jak 48 hodinách od úmrtí. Ze zákona si potom <a href="/slovnik-pojmu/vyzvednuti-zesnuleho/">nemocnice</a> nebo jiné instituce mají nárok účtovat s tím spojené náklady. Poplatek se sice do finální ceny promítne, ale nám nezůstane. Pošleme ho do dané nemocnice nebo instituce za vás. Podobný poplatek může vystavit krematorium pokud měl pozůstalý více než 100kg a musela by se použít nadměrná rakev.<br/><br/>U nás se s poplatkem setkáte pouze tehdy, když pro zesnulého jedeme mimo dané město. To se pak počítá podle <a href="/slovnik-pojmu/cena-za-dopravu/">najetých kilometrů</a>.<br/><br/>Pokud jsme pro vašeho blízkého jeli nebo pojedeme k vám domů, bude k celkové částce připočítán poplatek 1.990 Kč (v Praze a Ostravě 3.490 Kč), který pokrývá pohotovostní služby zaměstnanců zajišťujících akutní vyzvednutí.`
  },
  {
    otazka: "Je možné platit na splátky?",
    odpoved: "Ano, u nás můžete platit na splátky. Prostřednictvím našich partnerů jsme vám schopni splácení faktury zařídit do 24 hodin. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Poskytujete služby po celé ČR?",
    odpoved: `V současné chvíli poskytujeme pohřební služby téměř po celé ČR. Konkrétní město si můžete zkontrolovat v naší sekci <a href="/pobocky/">pobočky</a>. Pokud vám jde o lokalitu jinou, i tak se na nás neváhejte obrátit. V případě potřeby jsme schopni zajet kamkoliv.`
  },
  {
    otazka: "Jaké dokumenty budu potřebovat?",
    odpoved: `Pokud je zesnulý doma, budete k předání pohřební službě potřebovat <a href="/slovnik-pojmu/list-o-prohlidce-tela/">ohledací list</a>, který vystaví Zdravotnická záchranná služba nebo <a href="/slovnik-pojmu/koroner/">koroner</a>. Pokud je v nemocnici nebo pečovatelském domě, postarají se o vystavení dokumentu i předání pohřební službě příslušní zaměstnanci. Dále od vás budeme potřebovat <span>kopii vašeho občanského průkazu</span>, občanský průkaz zesnulé/ho a <span>plnou moc</span>, kterou s vámi podepíšeme online. Ta se musí vystavit proto, abychom mohli manipulovat s tělem zesnulého.`
  },
  {
    otazka: "Jak budu informován v průběhu procesu?",
    odpoved: `O každé změně (vyzvednutí zesnulého, provedení kremace, předpokládané datum doručení <a href="/poradna/kremacni-popel/">urny</a>...) vás budeme informovat telefonicky, případně e-mailem nebo SMS zprávou. Forma komunikace bude jen na vás a na vašich preferencích. Zároveň se můžete kdykoliv obrátit na naši zákaznickou linku.`
  },
  {
    otazka: "Jak se ke mně dostane popel zesnulého?",
    odpoved: "Popel si budete moct sami vyzvednout na pobočce ve vašem okolí, nebo při využití Péče+ vám urnu doručíme osobně až domů. Na termín vyzvednutí vás včas upozorníme."
  },
  {
    otazka: "Kdo může urnu s popelem převzít?",
    odpoved: "Urnu si budete moc převzít pouze vy. Na termín vyzvednutí vás včas upozorníme. Pak se stačí jen řídit otevírací dobou dané pobočky, kde si urnu můžete vyzvednout. Nebo při využití Péče+ vám urnu doručíme osobně až domů."
  },
  {
    otazka: "Jak si můžu být jistý/á, že nedojde k záměně popela?",
    odpoved: `Krematorium je ze zákona o pohřebnictví povinno do rakve vkládat kovový plíšek s označením. Ten není během kremace poškozen a následně je i s popelem vložen do stejně označené <a href="/poradna/kremacni-popel/">urny</a>. Krematorium vede o celém procesu podrobnou evidenci, takže je záměna vyloučena.`
  },
];

const NoWrap = styled.span`
  white-space: nowrap;
`;

const BezObraduInner = styled.div`
  background: #FBFBFA;
  padding-bottom: 1px;
`;






const BezObradu = ({ data }) => {

  const isPlus470 = useMediaQuery({
    query: '(min-width: 470px)'
  });

  const isPlus1100 = useMediaQuery({
    query: '(min-width: 1100px)'
  });


  return (
    <Layout
      subMenuLong
      forcePohrbyMenu
      subMenuItems={[
        {
          name: "Pohřební služba",
          url: "/pohrebni-sluzba/",
        },
        {
          name: "Kde fungujeme?",
          url: "/pobocky/",
        },
        {
          name: "Parte",
          url: "/parte/",
        },
        {
          name: "Kremace bez obřadu",
          url: "/kremace-bez-obradu/",
        },
        {
          name: "Pohřeb s obřadem",
          url: "/pohreb-s-obradem/",
        },
        {
          name: "Netradiční pohřeb",
          url: "/netradicni-pohreb/",
        },
        {
          name: "Pohřeb miminka",
          url: "/pohreb-miminka/",
        },
      ]}
    >
      <SEO
        title="Kremace bez obřadu od 15 990 Kč | Pohřební služba Goodbye.cz"
        customTitle
        description="Postaráme se o kremaci vašeho blízkého. Urnu vám potom osobně doručíme až domů. To vše jednoduše, přes internet a za jednu transparentní cenu."
        image={"/kremaceOg.png"}
      />

      

      <StructuredHodnoceni />

      <BezObraduInner>
        
        <Jumbotron 
          h1="Důstojná kremace bez obřadu"
          p="Postaráme se o kremaci vašeho blízkého. Urnu s popelem vám potom osobně doručíme až domů. To vše jednoduše, přes internet a za jednu transparentní cenu."
          pozadi={data.big.childImageSharp.fluid}
          pozadiSmall={data.small.childImageSharp.fluid}
          recenzeText={<>Rychlost, spolehlivost, ochota, spolupráce, vše dokonalé. <span>Paní mě krok po kroku vedla, vše tak proběhlo v pořádku.</span></>}
          recenzeImg={data.recenzeSample.childImageSharp.fluid}
          recenzeImgSmall={data.recenzeSampleSmall.childImageSharp.fixed}
          zakaznikJmeno={"Zita J."}
          zakaznikKdo={"Zákaznice"}
          breadscrum={
            <Breadscrum
              visible
              stranky={[
                {name: "Domů", url: "/"},
                {name: "Pohřební služba", url: "/pohrebni-sluzba/"},
                {name: "Kremace bez obřadu", url: "/kremace-bez-obradu/"}
              ]}
            />
          }   
        />


        <ProcesBezObradu />
        
        <Features isPruh />

        <NaceneniV2
          h2={<>Žádné skryté poplatky,<br/>vše potřebné je již v ceně</>}
          p="To jak se rozloučíte, je jen na vás. My se postaráme o vše ostatní za jednu transparentní cenu."
          checklist={["Sjednání do pár minut přes internet", "Přeprava vašeho blízkého k nám", "Vyřízení veškerých dokumentů", "Tvorba parte", "Samotná kremace včetně poplatků", "Předání urny dle vašeho výběru"]}
          price="15 990 Kč"
          feature="Ušetříte 9 000 Kč oproti průměrné ceně pohřu v ČR"
          extrasText="Nabízíme i možnost připlatit si za jiné urny"
          extras={[
            {
              h4: "Kovové a keramické",
              p: "Dostupné ve více barevných kombinacích.",
              price: "1 990 - 2 990 Kč",
              img: data.kovove.childImageSharp.fixed,
            },
            {
              h4: "Dřevěné",
              p: "Tradiční i netradiční variatny v několika velikostech.",
              price: "3 190 - 3 390 Kč",
              img: data.drevene.childImageSharp.fixed,
            },
            {
              h4: "Ekologické",
              p: "Urny, které se po uložení do země sami rozloží.",
              price: "790 - 1 990 Kč",
              img: data.alternativni.childImageSharp.fixed,
            },
          ]}
        />

        <RecenzePohreb />

        <RegionsV2 />

        <Press />

        <SeVsimPomuzeme />

        <FAQ data={faqData}/>


      </BezObraduInner>
    </Layout>
  );
}

export const query = graphql`
  query {


    big: file(relativePath: { eq: "bezObraduPozadi.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    small: file(relativePath: { eq: "bezObraduPozadi.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    recenzeSample: file(relativePath: { eq: "zakaznice2.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    kovove: file(relativePath: { eq: "kovove.jpg" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    drevene: file(relativePath: { eq: "drevene.jpg" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    alternativni: file(relativePath: { eq: "alternativni.jpg" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    recenzeSampleSmall: file(relativePath: { eq: "zakaznice2Small.png" }) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }













    cenik: file(relativePath: { eq: "pohrbyCenik.png" }) {
      childImageSharp {
        fixed(width: 470) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    cenikSmall: file(relativePath: { eq: "pohrbyCenik.png" }) {
      childImageSharp {
        fixed(width: 370) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    jakToFunguje1: file(relativePath: { eq: "jakToFunguje1.png" }) {
      childImageSharp {
        fixed(width: 186) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    jakToFunguje2: file(relativePath: { eq: "jakToFunguje2.png" }) {
      childImageSharp {
        fixed(width: 172) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    jakToFunguje3: file(relativePath: { eq: "jakToFunguje3.png" }) {
      childImageSharp {
        fixed(width: 160) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    cremationTip: file(relativePath: { eq: "cremationTip.png" }) {
      childImageSharp {
        fixed(width: 350) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    cremationTipSmall: file(relativePath: { eq: "cremationTip.png" }) {
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

  }
`;

export default BezObradu;


